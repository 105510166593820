import Axios from 'axios';
import { CANCEL } from 'redux-saga';
import { configure } from 'axios-hooks';
import Cookies from 'js-cookie';
import { pathOr } from 'rambda';
import { hmacAdapter, logout } from './common';
import { AUTH_TOKEN } from './constants';

const source = Axios.CancelToken.source();

const axios = Axios.create({
  baseURL: process.env.REACT_APP_SERVER_API_URL,
  timeout: 600000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'X-Apikey': process.env.REACT_APP_API_KEY,
  },
  cancelToken: source.token,
});

configure({ axios });

const adapter = hmacAdapter(axios);

const PostApi = ({
  url,
  method,
  data,
  handleResponse,
  handleError,
  params = null,
}) => {
  const token = Cookies.get(AUTH_TOKEN);
  axios.defaults.headers.authorization = `TOKEN ${token}`;
  return axios
    .request({ method, url, data, adapter, params })
    .then((response) => {
      if (handleResponse) {
        return handleResponse(response);
      }
      return null;
    })
    .catch((error) => {
      console.trace('error', error);
      const status = pathOr(0, 'response.status', error);
      if (status === 401) {
        logout();
        return null;
      }
      if (handleError) {
        return handleError(error);
      }
      return error;
    });
};

const GetApi = ({
  url,
  params,
  handleResponse,
  handleError,
  language = null,
  cancelToken,
  headers = null,
}) => {
  const token = Cookies.get(AUTH_TOKEN);

  axios.defaults.headers.authorization = `TOKEN ${token}`;
  if (language && language.code) {
    axios.defaults.headers['Accept-Language'] = language.code;
  } else {
    axios.defaults.headers['Accept-Language'] = 'sk';
  }

  const config = {
    cancelToken,
    params,
    adapter,
  };

  if (headers) {
    config.headers = headers;
  }

  const promise = axios
    .get(url, config)
    .then((response) => {
      if (handleResponse) {
        return handleResponse(response);
      }
      return null;
    })
    .catch((error) => {
      if (handleError) {
        const status = pathOr(0, 'response.status', error);
        if (status === 401) {
          logout();
        } else {
          return handleError(error);
        }
      }
      return null;
    });
  // console.log(promise);
  promise[CANCEL] = () => {
    // console.log('cancel invoked');
    return cancelToken;
  };
  return promise;
};

export { PostApi, GetApi };
